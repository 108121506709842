import React from 'react'
import Layout from '../components/Layout'
import AnimatedText from '../components/AnimatedText'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import analogclock from "../assets/analogclock.jpg"
import landing from "../assets/landing.jpg"
import bikestar from "../assets/bikestar.jpg"
import wheaterdesign from "../assets/wheater_app_design.png"
import food from "../assets/food.jpg"
import barbershop from "../assets/barbershop.jpg"
import hotel from "../assets/hotel.jpg"
import TransitionEffect from '../components/TransitionEffect';
import hair from "../assets/hair.jpg";
import beauty from "../assets/beauty.jpg";
import hopiheKlima from "../assets/hopiheKlima.png"
import carwash from "../assets/carwash.png";
import restaurant from "../assets/restaurant.jpg"
const FeaturedProject = ({type,title,summary,img,link,github}) => {
    return (
        <article className='relative w-full flex items-center justify-between rounded-3xl border border-solid border-dark bg-light shadow-2xl p-10 rounded-br-2xl dark:bg-dark
        dark:border-light lg:flex-col lg:p-8 xs:rounded-2xl xs:rounded-br-3xl xs:p-4'>
            <div className='absolute top-0 -right-3 -z-10 w-[101%] h-[103%] rounded-[2.5rem] bg-dark rounded-br-3xl dark:bg-light xs:-right-2 sm:h-[102%] xs:w-full xs:rounded-[1.5rem]'></div>
            <Link to={link} target='_blank'className='w-1/2 cursor-pointer overflow-hidden rounded-lg lg:w-full'>
                <motion.img src={img} alt={title} className='w-full h-auto' whilehover={{ scale: 1.05 }} transition={{ duration: 0.2 }}
                priority="true" sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
            </Link>
            <div className='w-1/2 flex flex-col items-start justify-between pl-6 lg:w-full lg:pl-0 lg:pt-'>
                <span className='text-primary font-medium text-xl dark:text-primaryDark xs:text-base'>{type}</span>
                <Link to={link} target='_blank' className='hover:underline underline-offset-2 '>
                    <h2 className='my-2 w-full text-left text-4xl font-bold dark:text-light sm:text-sm'>{title }</h2>
                </Link>
                <p className='my-2 font-medium text-dark dark:text-light sm:text-sm'>{summary}</p>
                <div className='mt-2 flex items-center'>
                    <Link to={link} target='_blank' className='rounded-lg bg-dark text-light p-2 px-6 text-lg font-semibold  dark:bg-light dark:text-dark sm:px-4 sm:text-base'>Visit Project</Link>
                </div>
            </div>
        </article>
    )
}
const Project = ({title,type,img,link,github}) => {
    return (
        <article className='w-full flex flex-col items-center justify-center rounded-2xl border border-solid border-dark bg-light  relative dark:bg-dark dark:border-light xs:p-4'>
             <div className='absolute top-0 -right-3 -z-10 w-[101%] h-[103%] rounded-[2rem] bg-dark rounded-br-3xl dark:bg-light md:-right-2 md:w-[101%] sm:h-[102%] xs:rounded-[1.5rem]'></div>
            <Link to={link} target='_blank'className='w-full cursor-pointer overflow-hidden rounded-lg'>
                <motion.img src={img} alt={title } className='w-full object-cover max-h-[300px]' whilehover={{scale:1.05}} transition={{duration:0.2}} />
            </Link>
            <div className='w-full flex flex-col items-start justify-between mt-4 '>
                <span className='text-primary dark:text-primaryDark font-medium text-xl lg:text-lg md:text-base mx-2'>{type}</span>
                <Link to={link} target='_blank' className='hover:underline underline-offset-2'>
                    <h2 className='my-2 mx-2 w-full text-left text-3xl font-bold lg:text-2xl'>{title }</h2>
                </Link>
                
                <div className='w-full m-2 flex items-center justify-between'>
                <Link to={link} target='_blank' className='mx-2 underline text-lg font-semibold md:text-base'>Visit</Link>
                    
                </div>
            </div>
        </article>
    )
}
const projects = () => {
    return (
        <>
            <TransitionEffect />
            <main className='w-full  flex flex-col items-center justify-center dark:text-light min-h-screen'>
              <Layout className='pt-16'>
                  <AnimatedText text={'Imagination Trumps Knowledge!'} className='mb-16 lg:!text-7xl sm:mb-8 sm:!text-6xl xs:!text-4xl'/>
                    <div className='grid grid-cols-12 gap-24 gap-y-32 xl:gap-x-16 lg:gap-x-8 md:gap-y-24 sm:gap-x-0'>
                    <div className='col-span-12 '>
                      <FeaturedProject
                              title={'Responsive restaurant website'}
                              summary={`I have created a website for a restaurant. It is designed in a modern and clean style.Bon appétit!`}
                              link={'https://restaurant.dovigyel.hu/'}
                              img={restaurant}
                              type={'Featured Project'}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Hópihe Klíma website'}
                              link={'https://www.hopihe-klima.hu/'}
                              img={hopiheKlima}
                              type={'Landingpage Html+Css+Js'}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Car Wash Website'}
                              link={'https://carwash.dovigyel.hu/'}
                              img={carwash}
                              type={'Landingpage Html+Css+Js'}
                          />
                        </div>

                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Hair Dresser responsive website'}
                              link={'https://hairdresser.dovigyel.hu/'}
                              img={hair}
                              type={'Landingpage Html+Css+JS'}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Beauty Studio responsive website'}
                              link={'https://beauty.dovigyel.hu/'}
                              img={beauty}
                              type={'Landingpage Html+Css+Js'}
                          />
                      </div>

                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Barber Shop website building'}
                              link={'https://barbershop.dovigyel.hu/'}
                              github={'https://github.com/DoviLaci/barber_shop'}
                              img={barbershop}
                              type={'Website Html+Css+JS'}
                          />
                        </div>
                        <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'The Hotel Landingpage'}
                              link={'https://hotel.dovigyel.hu/'}
                              github={'https://github.com/DoviLaci/hotel_booking_landing'}
                              img={hotel}
                              type={'Landingpage Html+Css+Js'}
                          />
                      </div>
                    <div className='col-span-12 '>
                      <FeaturedProject
                              title={'Modern UI Restaurant website'}
                              summary={`I made a modern restaurant website using React and Framer Motion. My website is responsive, elegant, and has modern UI solutions. Let me show you some of the features of my website.`}
                              link={'https://restaurant2.dovigyel.hu/'}
                              img={restaurant}
                              type={'Featured Project'}
                          />
                        </div>
    
                      <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'React + Vite Weather Application'}
                              link={'https://weather.dovigyel.hu/'}
                              github={'https://github.com/DoviLaci/weather_app'}
                              img={wheaterdesign}
                              type={'Weather app with React'}
                          />
                      </div>
                      <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Weather app design'}
                              link={'https://www.figma.com/file/jqBFTWR5DDXBL1JbMUGz36/Wheater_app_full?type=design&node-id=0%3A1&mode=design&t=drxxhDHSI5hH8Li3-1'}
                              github={'/'}
                              img={wheaterdesign}
                              type={'Design Tool Figma'}
                          />
                        </div>
                        <div className='col-span-12 '>
                      <FeaturedProject
                              title={'BikeStar.at Website Design'}
                              summary={`The website has a simple and elegant design, with a black and yellow color scheme that matches the BikeStar.at brand identity. The website is responsive and adaptive, which means it can adjust to different screen sizes and devices. The website is also interactive and engaging, which means it can provide a smooth and enjoyable user experience.`}
                              link={'https://www.figma.com/file/syL7k1rpGlVdyRl2QYljsy/Bikestar.at?type=design&node-id=245%3A548&mode=design&t=9x4BfKynY3ieTjhA-1'}
                              img={bikestar}
                              type={'Featured Project'}
                          />
                        </div>
                        <div className='col-span-12'>
                          <FeaturedProject
                              title={'Ordering Application'}
                              summary={`Using these technologies: Nextjs-tailwind css-MongoDb. I created a food ordering application that showcases my skills and creativity. The food ordering application is called Burger Eat, and it is a platform where users can order delicious and affordable food from their favorite restaurants.`}
                              link={'/'}
                              github={'/'}
                              img={food}
                              type={'Nextjs+Tailwind Css+MongoDb'}
                          />
                        </div>
                      <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Animated Onegpage design'}
                              link={'https://www.figma.com/proto/VShVL8dZfQbL8LZ4ADIosY/Untitled?page-id=0%3A1&type=design&node-id=1-2&viewport=420%2C548%2C0.36&t=p4RFxpVAMbLHkG81-1&scaling=scale-down&starting-point-node-id=1%3A2&mode=design'}
                              img={landing}
                              type={'Design Tools Figma'}
                          />
                      </div>
                      <div className='col-span-6 sm:col-span-12'>
                      <Project
                              title={'Analogclock'}
                              link={'https://analogclock.dovigyel.hu/'}
                              github={'https://github.com/DoviLaci/Js_small_projects/tree/main/analogClock'}
                              img={analogclock}
                              type={'Html+Css+Javascript'}
                          />
                      </div>
                  </div>
              </Layout>
          </main>
        </>
  )
}

export default projects