import posthog from "posthog-js";
import { useState } from "react";

function CookieBanner() {

    const [showBanner, setShowBanner] = useState(true);

    const acceptCookies = () => {
        posthog.opt_in_capturing();
        setShowBanner(false);
    };

    const declineCookies = () => {
        posthog.opt_out_capturing();
        setShowBanner(false);
    }

    return (
        <div className="">
           {showBanner && (
  <div className="w-full h-40 z-10 flex flex-col items-center justify-center bg-dark dark:bg-white absolute bottom-0">
    <p className="text-white dark:text-dark">
      To provide you a better user experience we use cookies. Through the use of
      www.dovigyel.hu you agree to the usage of cookies.
      <a
        href="https://cookie.dovigyel.hu"
        className="underline underline-offset-1 text-blue-500"
      >
        Privacy Policy
      </a>
    </p>
    <div className="w-full mx-auto flex items-center justify-center ">
      <button
        onClick={acceptCookies}
        className="text-black mt-2 mx-2 hover:bg-green-700 border rounded-md bg-green-500"
      >
        Accept
      </button>
      <button
        onClick={declineCookies}
        className="text-black mt-2 mx-2 hover:bg-red-700 border rounded-md bg-red-500"
      >
        Decline
      </button>
    </div>
  </div>
)}

        </div>

    )
}

export default CookieBanner;