import React from "react";
import mongoDb from '../assets/mongodb.png';
import figma from '../assets/figma.png';
import wordpress from '../assets/wordpress.png';
import nodejs from '../assets/node.png'
import html from "../assets/html.png";
import css from "../assets/css.png";
import javascript from "../assets/javascript.png";
import reactImage from "../assets/react.png";
import github from "../assets/github.png";
import tailwind from "../assets/tailwind.png";

const Skills = () => {
  const techs = [
    {
        id: 1,
        src: github,
        title: "GitHub",
        style: "shadow-gray-400",
    },
    {
      id: 2,
      src: figma,
      title: "Figma",
      style: "shadow-red-500",
    },
    {
      id: 3,
      src: mongoDb,
      title: "MongoDb",
      style: "shadow-green-500",
    },
    {
      id: 4,
      src: nodejs,
      title: "NodeJs",
      style: "shadow-green-500",
    },
    {
      id: 5,
      src: reactImage,
      title: "ReactJs",
      style: "shadow-sky-400",
    },
    {
      id: 6,
      src: tailwind,
      title: "Tailwind Css",
      style: "shadow-sky-400",
    },
    {
      id: 7,
      src: javascript,
      title: "Javascript",
      style: "shadow-yellow-400",
      },
      {
        id: 8,
        src:css,
        title: "Css",
        style: "shadow-sky-400",
      },
      {
        id: 9,
        src:html,
        title: "Html5",
        style: "shadow-orange-400",
      },
      {
        id: 10,
        src:wordpress,
        title: "Wordpress",
        style: "shadow-sky-400",
      },
  ];

  return (
    <div
      name="skills"
      className='w-full h-screen relative rounded-full flex items-center justify-center bg-light dark:bg-dark mt-64'
    >
      <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center w-full h-full text-white">
        <div>
          <p className="text-4xl font-bold border-b-4 border-gray-500 p-2 inline text-dark dark:text-light">
            Skills
          </p>
          <p className="py-6 text-dark dark:text-light">These are the technologies I've worked with</p>
        </div>

        <div className="w-full grid grid-cols-2 sm:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0 ">
          {techs.map(({ id, src, title, style }) => (
            <div
              key={id}
              className={`shadow-lg hover:scale-105 duration-500 py-2 rounded-lg ${style} bg-dark`}
            >
              <img src={src} alt="" className="w-20 mx-auto" />
              <p className="mt-4">{title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;