import Navbar from './components/Navbar';
import Home from './pages/Home';
import "./App.css"
import Footer from "./components/Footer"
import About from "./pages/About.jsx"
import Projects from "./pages/Projects.jsx"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CookieBanner from "./components/CookieBanner"
import posthog from 'posthog-js';

function App() {
  return (
    
    <BrowserRouter>
      <Navbar />
      {posthog.has_opted_in_capturing() || posthog.has_opted_out_capturing() ? null : <CookieBanner />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/projects' element={<Projects/>}/>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
