import React from 'react'
import Layout from "../components/Layout";
import AnimatedText from "../components/AnimatedText";
import {Link } from 'react-router-dom';
import profilePic from "../assets/developer-pic-1.png"
import lightBulb from "../assets/miscellaneous_icons_1.svg";
import HireMe from '../components/HireMe';
import TransitionEffect from '../components/TransitionEffect';

const Home = () => {
  return (
      <>
        <TransitionEffect />
        <div className='flex  items-center text-dark w-full h-screen dark:text-light  md:w-full md:min-h-screen'>
        <Layout className='pt-0 md:pt-16 sm:pt-8'>
          <div className='flex items-center justify-between w-full lg:flex-col '>
            <div className='w-1/2 md:w-full'>
              <img src={profilePic} alt="profile" className='w-full h-auto lg:hidden md:inline-block md:w-full drop-shadow-[-20px_-7px_9px_rgba(0,0,0,0.3)] dark:drop-shadow-[-20px_-7px_9px_#0ef]'/>
            </div>
            <div className='w-1/2 flex flex-col items-center self-center lg:w-full lg:text-center'>
              <AnimatedText text="Turning Vision Into Reality With Code And Design." className='!text-6xl !text-left xl:!text-5xl lg:!text-center lg:!text-6xl md:!text-5xl sm:!text-3xl'/>
              <p className='my-4 text-base font-medium md:text-sm sm:text-xs'> As a skilled full-stack developer, I am dedicated to turning ideas into innovative web applications. 
                  Explore my latest projects and articles, showcasing my expertise in React.js and web development.
              </p>
              <div className='flex items-center self-start mt-2 lg:self-center'>
                <Link to="/laszlo_dovigyel.pdf" target='_blank' className='flex items-center bg-dark text-light p-2.5 px-6 rounded-lg text-lg font-semibold hover:bg-light hover:text-dark
                 border-2 border-solid border-transparent hover:border-dark dark:bg-light dark:text-dark
                 hover:dark:bg-dark hover:dark:text-light hover:dark:border-light md:p-2 md:px-4 md:text-base' download={true} >Resume</Link>
                <Link to="mailto:dovigyel.laszlo1990@gmail.com" className='ml-4 text-lg font-medium capitalize text-dark underline dark:text-light md:text-base'>Contact</Link>
              </div>
            </div>
          </div>
        </Layout>
        <HireMe />
        <div className='absolute right-8 bottom-8 inline-block w-24 md:hidden'>
          <img src={lightBulb} alt='D&D' className='w-full h-auto'/>
        </div>
      </div>
      </>
  )
}

export default Home